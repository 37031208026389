import { useState, useEffect } from 'react';
import * as yup from 'yup';

import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Divider} from '@mui/material';

import { useMentoringContext } from '../../shared/contexts';
import { IMaintenancePlan } from '../../shared/contexts/MaintenancePlanContext/types';
import { MaintenancePlanService } from '../../shared/services/api/maintenance-plan/MaintenancePlanService';
import { useSnackbar } from 'notistack';
import { EASButton, EASInput} from '../../shared/forms';


import AddIcon from '@mui/icons-material/Add';

interface IMaintenancePlanItemEditProps {
  strategies?:IMaintenancePlan;
  itemEdit: 'routine' | 'team' | 'leader' | 'clients_and_peers' | null;
  open: boolean;
  setOpen: (open:boolean) => void;
  setUpdateList:() => void;

}
export const MaintenancePlanItemEdit: React.FC<IMaintenancePlanItemEditProps> = ({strategies, open, itemEdit, setOpen, setUpdateList})  => {

  const [saveLoading, setSaveLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [maintenancePlan, setMaintenancePlan] = useState<IMaintenancePlan>({});
  const [titleStrategies, setTitleStrategies] = useState<string | null>(null);
  const [textMaintenancePlanStrategies, setTextMaintenancePlanStrategies] = useState<string | undefined>('');
  const [textMaintenancePlanStrategiesError, setTextMaintenancePlanStrategiesError] = useState<string | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const { mentory } = useMentoringContext();
  
  const handleClose = () => {
    setMaintenancePlan({});
    setTextMaintenancePlanStrategies('');
    setTextMaintenancePlanStrategiesError(null);
    setOpen(false);
  };

  useEffect(() => {
    async function loadEditMaintenancePlan() {
      setIsLoading(true);
      if(strategies){
        setTitleStrategies(()=> { 
          switch(itemEdit) {
            case 'routine': return 'Rotina';
            case 'team': return 'Equipe';
            case 'leader': return 'Líder';
            case 'clients_and_peers': return 'Clientes e Pares';
            default: return 'Rotina';
        }});

        setTextMaintenancePlanStrategies(()=> { 
          switch(itemEdit) {
            case 'routine': return strategies?.routine_maintenance_plan;
            case 'team': return strategies?.team_maintenance_plan;
            case 'leader': return strategies?.leader_maintenance_plan;
            case 'clients_and_peers': return strategies?.clients_and_peers_maintenance_plan;
            default: return strategies?.routine_maintenance_plan;
        }});
        setMaintenancePlan(strategies); 
      }
            
    }
    if(open==true)
      loadEditMaintenancePlan().then(e => {
        setIsLoading(false);
      });

  }, [open]);

  const maintenancePlanSchema = yup.object().shape({    
    textMaintenancePlanStrategies: yup.string().required('Descreva o aprendizado'),
  });

  const handleButtonSave = () => {
    maintenancePlanSchema
      .validate(  {textMaintenancePlanStrategies}, { abortEarly: false })
      .then(validateValues => {
        setSaveLoading(true);
        if(maintenancePlan && maintenancePlan.id_maintenance_plan) {
          const editMaintenancePlan: IMaintenancePlan = {
            id_maintenance_plan: maintenancePlan.id_maintenance_plan,
            id_mentory: mentory?.id_mentory,
          };    
          switch(itemEdit){
            case 'routine':
              editMaintenancePlan.routine_maintenance_plan = validateValues.textMaintenancePlanStrategies;
            break;
            case 'team':
              editMaintenancePlan.team_maintenance_plan = validateValues.textMaintenancePlanStrategies;
            break;
            case 'leader':
              editMaintenancePlan.leader_maintenance_plan = validateValues.textMaintenancePlanStrategies;
            break;
            case 'clients_and_peers':
              editMaintenancePlan.clients_and_peers_maintenance_plan = validateValues.textMaintenancePlanStrategies;
            break;
          }
          MaintenancePlanService.updateMaintenancePlan(editMaintenancePlan)
          .then((SessionResponse) => {
            if (SessionResponse instanceof Error) {
              setSaveLoading(false);
              enqueueSnackbar('Erro ao atualizar estratégia.', { variant:'error'});
            } else {
              setSaveLoading(false);
              enqueueSnackbar('Estratégia atualizada com sucesso!', { variant:'success'});
              setMaintenancePlan({});
              setTextMaintenancePlanStrategiesError(null);
              setTextMaintenancePlanStrategies('');
              setOpen(false);
              setUpdateList();
            }
          }).catch(() => {
            setSaveLoading(false);
            enqueueSnackbar('Erro ao atualizar estratégia.', { variant:'error'});
          });
        }
        else {
          const newMaintenancePlan: IMaintenancePlan = {
            id_maintenance_plan: maintenancePlan.id_maintenance_plan,
            id_mentory: mentory?.id_mentory,
            routine_maintenance_plan: (itemEdit == 'routine' ? validateValues.textMaintenancePlanStrategies : ''),
            team_maintenance_plan: (itemEdit == 'team' ? validateValues.textMaintenancePlanStrategies : ''),
            leader_maintenance_plan: (itemEdit == 'leader' ? validateValues.textMaintenancePlanStrategies : ''),
            clients_and_peers_maintenance_plan: (itemEdit == 'clients_and_peers' ? validateValues.textMaintenancePlanStrategies : ''),
          };    
        
          MaintenancePlanService.createMaintenancePlan(newMaintenancePlan)
          .then((SessionResponse) => {
            if (SessionResponse instanceof Error) {
              setSaveLoading(false);
              enqueueSnackbar('Erro ao atualizar estratégia.', { variant:'error'});
            } else {
              setSaveLoading(false);
              enqueueSnackbar('Estratégia atualizada com sucesso!', { variant:'success'});
              setMaintenancePlan({});
              setTextMaintenancePlanStrategiesError(null);
              setTextMaintenancePlanStrategies('');
              setOpen(false);
              setUpdateList();
            }
          }).catch(() => {
            setSaveLoading(false);
            enqueueSnackbar('Erro ao atualizar estratégia.', { variant:'error'});
          });

        }
      })
      .catch((errors: yup.ValidationError) => {
        setSaveLoading(false);
        errors.inner.forEach(error => {
            if(error.path){
                //const keyError = error.path;                    
                setTextMaintenancePlanStrategiesError(error.message);                        
            }
        });
      });

  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle>Editar Estratégia</DialogTitle>
      <Divider/>
      <DialogContent>
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          {saveLoading && (                   
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress variant='indeterminate' />
            </Box>
          )}
          {!saveLoading && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <EASInput 
                  isLoading={isLoading}
                  label={titleStrategies}
                  value={textMaintenancePlanStrategies} 
                  onChange={(event) => setTextMaintenancePlanStrategies(event.target.value)}  
                  onFocus={() => setTextMaintenancePlanStrategiesError('')}
                  error={!!textMaintenancePlanStrategiesError}
                  helperText={textMaintenancePlanStrategiesError}
                  multiline
                  rows={6}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{mr:2, mb:1}}>
        <EASButton variant="outlined" onClick={handleClose} disabled={saveLoading}>Cancelar</EASButton>
        <EASButton 
          isLoading={isLoading}
          variant='contained'
          disabled={saveLoading}
          endIcon={saveLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <AddIcon/>}
          onClick={handleButtonSave}>Salvar Alterações</EASButton>
      </DialogActions>
    </Dialog> 

  );
};
