import React, { useEffect, useState } from 'react';
import {CircularProgress, Typography, Grid, Box, Card, useTheme, Paper, Skeleton, CardHeader, CardContent, Divider, Alert } from '@mui/material/';
import { useSnackbar } from 'notistack';

import { EASButton, EASButtonList, EASInput } from '../../shared/forms';
import { useAuthContext, useMentoringContext } from '../../shared/contexts';

import { EASCard, EASDialogAlert } from '../../shared/components';

import PendingActionsIcon from '@mui/icons-material/PendingActions';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import GroupsIcon from '@mui/icons-material/Groups';
import Diversity3Icon from '@mui/icons-material/Diversity3';

import AddIcon from '@mui/icons-material/Add';

import { IMaintenancePlan, IMaintenancePlanLesson } from '../../shared/contexts/MaintenancePlanContext/types';
import { MaintenancePlanService } from '../../shared/services/api/maintenance-plan/MaintenancePlanService';
import { MaintenancePlanItemLessonEdit } from './MaintenancePlanItemLessonEdit';
import { MaintenancePlanItemLessonNew } from './MaintenancePlanItemLessonNew';
import { MaintenancePlanItemEdit } from './MaintenancePlanItemEdit';


export const MaintenancePlanItems: React.FC = ()=> {

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthContext();
  const { mentory } = useMentoringContext();
  
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saveLoadingLesson, setSaveLoadingLesson] = useState<boolean>(false);
  const [maintenancePlan, setMaintenancePlan] = useState<IMaintenancePlan>();
  const [maintenancePlanLesson, setMaintenancePlanLesson] = useState<IMaintenancePlanLesson>();

  const [updateList, setUpdateList] = useState(true);
  const [idMaintenancePlanLesson, setIdMaintenancePlanLesson] = useState<string | null>(null);
  
  const [openLessonEdit, setOpenLessonEdit] = useState<boolean>(false);
  const [openLessonNew, setOpenLessonNew] = useState<boolean>(false);
  const [opeLessonRemove, setOpenLessonRemove] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const [itemEdit, setItemEdit]  = useState<'routine' | 'team' | 'leader' | 'clients_and_peers' | null>(null);

  useEffect(() => {
    async function getMentory() {
        setIsLoading(true);
        const response = await MaintenancePlanService.getMaintenancePlan(mentory?.id_mentory);
        if (response instanceof Error) {
          setMaintenancePlan({});
          setIsLoading(false);
        } else {
            if(response){
                setMaintenancePlan(response);
                setIsLoading(false);
            }
            else{         
              setMaintenancePlan({});
              setIsLoading(false);
            }
        }
    }
    getMentory();
  }, [updateList]);

  const handleClickEditLesson = (maintenancePlanLesson?:IMaintenancePlanLesson) => {
    if(maintenancePlanLesson){
      setMaintenancePlanLesson(maintenancePlanLesson);
      setOpenLessonEdit(true);
    }
  };

  const handleClickDeleteLesson  = (id_maintenance_plan_lesson?:string) => {
    if(id_maintenance_plan_lesson){
      setIdMaintenancePlanLesson(id_maintenance_plan_lesson);
      setOpenLessonRemove(true);
    }
  }; 

  const handleConfirmeDeleteLesson = () => {
    deleteMaintenancePlanLesson();
    setOpenLessonRemove(false);
  };

  const handleCancelDeleteLesson = () => {
    setOpenLessonRemove(false);
    setIdMaintenancePlanLesson(null);
  };

  const deleteMaintenancePlanLesson = () => {
    if(idMaintenancePlanLesson){
      MaintenancePlanService.deleteMaintenancePlan(idMaintenancePlanLesson).then((objectiveDetail) => {
        if (objectiveDetail instanceof Error) {
          setIdMaintenancePlanLesson(null);
          enqueueSnackbar('Erro ao tentar remover aprendizado!', { variant:'error'});
        } else {
            setIdMaintenancePlanLesson(null);
            setUpdateList(!updateList); 
            enqueueSnackbar('Aprendizado removido com sucesso!', { variant:'success'});
        }
      });
    }
  };


  
  const handleClickEdit = (itemEdit:'routine' | 'team' | 'leader' | 'clients_and_peers' | null) => {
    if(itemEdit && maintenancePlan){
      setItemEdit(itemEdit);
      setOpenEdit(true);
    }
  };


  return (
        <EASCard 
          titleCard="Plano de Manutenção"
          bgcolorContent={theme.palette.background.default}
        >
          {(mentory?.status_mentory === '2' ) && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography variant="h5">Este programa foi encerrado</Typography>
              {(mentory?.id_user_mentorado === user?.id)  && (
                <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
              )}
            </Paper>
          )}
          <Grid container spacing={2}>
          {isLoading && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={310} />
                </Grid>
                <Grid item xs={12} md={8} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={310} />
                </Grid>
              </Grid>
            </Grid>
          )}

          {!isLoading && (
            <>
            <Grid item xs={12} md={4}>
              <Card sx={{borderRadius : 2,}} variant='outlined' elevation={0}>
                  <CardHeader
                    title='Principais Aprendizados'
                    titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
                    action={<EASButton    
                              sx={{mr:1}}                
                              isLoading={isLoading}
                              variant="contained"
                              size="small"
                              disabled={saveLoadingLesson}
                              endIcon={saveLoadingLesson ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <AddIcon/>}
                              onClick={() => setOpenLessonNew(true)}>Novo</EASButton>}
                  />
                <Divider/>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Quais foram os principais aprendizados?</Typography>
                        <Typography sx={{textAlign:'justify'}}>Quais foram os aprendizados que representam uma mudança de mindset (um novo jeito de perceber as situações)?</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {!isLoading && (maintenancePlan?.lessons && maintenancePlan.lessons.length ? (maintenancePlan.lessons.map((lesson) => (
                        
                        <Card key={lesson.id_maintenance_plan_lesson}  sx={{borderRadius : 2, p:2, mb:1, bgcolor: theme.palette.background.default}} variant='outlined' elevation={0} >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="caption" sx={{my:2, textAlign:'justify'}}>{lesson?.text_maintenance_plan_lesson}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={true}>
                              </Grid>
                              <Grid item zeroMinWidth>
                                <EASButtonList 
                                    title="Editar Aprendizado"
                                    onClick={() => handleClickEditLesson(lesson)}
                                    typeIcon="edit"
                                  />
                                  <EASButtonList 
                                    title="Remover Aprendizado"
                                    onClick={() => handleClickDeleteLesson(lesson.id_maintenance_plan_lesson)} 
                                    typeIcon="remove"
                                  />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                      ))) : (
                        <Alert severity="warning">Não há aprendizados cadastrados!</Alert>
                      ))}
                    </Grid>
                  </Grid>
                  <MaintenancePlanItemLessonNew open={openLessonNew} setOpen={(e) => setOpenLessonNew(e)} setUpdateList={() => setUpdateList(!updateList)} />
                  <MaintenancePlanItemLessonEdit lesson={maintenancePlanLesson} open={openLessonEdit} setOpen={(e) => setOpenLessonEdit(e)} setUpdateList={() => setUpdateList(!updateList)} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={8}>
              <Card sx={{borderRadius : 2}} variant='outlined' elevation={0}>
                <CardHeader
                    title='Estratégias Executadas'
                    titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
                />
                <Divider/>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Quais estratégias estão sendo executadas?</Typography>
                      <Typography sx={{textAlign:'justify'}}>Quais Estratégias / ações que estão sendo executadas para a sustentabilidade do desenvolvimento após o processo de Mentoria?</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Card sx={{borderRadius : 2, height:'auto'}} variant='outlined' elevation={0}>
                            <CardHeader
                              title={ <Box sx={{display: 'flex', justifyContent:'center',  flexDirection:'row'}}>
                                          <PendingActionsIcon /> 
                                          <Typography sx={{ marginTop:'4px', marginLeft:'5px', fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold',}}>Rotina</Typography>
                                      </Box>
                                      }
                              action={<EASButtonList 
                                              title="Editar Estratégias para Rotina"
                                              onClick={() => handleClickEdit('routine')}
                                              typeIcon="edit"
                                            />}
                            />
                            <Divider/>
                            <CardContent >
                              <Grid container spacing={1}>
                                  <Grid item xs={12} sx={{minHeight:'70px'}}>
                                      <Typography variant="h5" sx={{ fontSize: '0.85rem', lineheight:'1.334'}}>Descreva quais rotinas você manterá para sustentar as competências aprimoradas no processo de mentoria</Typography>
                                  </Grid>  
                                  <Grid item xs={12}>
                                    <Card sx={{borderRadius : 2, p:2, mb:1, bgcolor: theme.palette.background.default}} variant='outlined' elevation={0} >
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sx={{minHeight:'150px'}}>
                                          <Typography variant="caption" sx={{my:2, textAlign:'justify'}}>{maintenancePlan?.routine_maintenance_plan}</Typography>
                                        </Grid>
                                      </Grid>
                                    </Card>
                                  </Grid>
                              </Grid>
                            </CardContent>
                          </Card>  
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Card sx={{borderRadius : 2, height:'auto'}} variant='outlined' elevation={0}>
                            <CardHeader
                              title={<Box sx={{display: 'flex', justifyContent:'center',  flexDirection:'row'}}>
                                          <Diversity3Icon /> 
                                          <Typography sx={{ marginTop:'4px', marginLeft:'5px', fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold',}}>Equipe</Typography>
                                      </Box>
                                    }
                              action={<EASButtonList 
                                        title="Editar Estratégias para Equipe"
                                        onClick={() => handleClickEdit('team')}
                                        typeIcon="edit"
                                      />}

                            />
                            <Divider/>
                            <CardContent >
                              <Grid container spacing={1}>
                                  <Grid item xs={12} sx={{minHeight:'70px'}}>
                                      <Typography variant="h5" sx={{ fontSize: '0.85rem', lineheight:'1.334'}}>Descreva quais ações foram estabelecidas junto a sua equipe para envolvê-los no processo de aprimoramento das competências da mentoria.</Typography>
                                  </Grid>  
                                  <Grid item xs={12}>
                                    <Card sx={{borderRadius : 2, p:2, mb:1, bgcolor: theme.palette.background.default}} variant='outlined' elevation={0} >
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sx={{minHeight:'150px'}}>
                                          <Typography variant="caption" sx={{my:2, textAlign:'justify'}}>{maintenancePlan?.team_maintenance_plan}</Typography>
                                        </Grid>
                                      </Grid>
                                    </Card>
                                  </Grid>
                              </Grid>
                            </CardContent>
                          </Card> 
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Card sx={{borderRadius : 2, height:'auto'}} variant='outlined' elevation={0}>
                            <CardHeader
                              title={ <Box sx={{display: 'flex', justifyContent:'center',  flexDirection:'row'}}>
                                          <FollowTheSignsIcon /> 
                                          <Typography sx={{ marginTop:'4px', marginLeft:'5px', fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold',}}>Líder</Typography>
                                      </Box>
                                    }
                              action={<EASButtonList 
                                        title="Editar Editar Estratégias para Líder"
                                        onClick={() => handleClickEdit('leader')}
                                        typeIcon="edit"
                                      />}
                            />
                            <Divider/>
                            <CardContent >
                              <Grid container spacing={1}>
                                  <Grid item xs={12} sx={{minHeight:'70px'}}>
                                      <Typography variant="h5" sx={{ fontSize: '0.85rem', lineheight:'1.334'}}>Descreva quais combinações você fez com seu Líder para ajudá-lo na manutenção dos novos comportamento</Typography>
                                  </Grid>  
                                  <Grid item xs={12}>
                                    <Card sx={{borderRadius : 2, p:2, mb:1, bgcolor: theme.palette.background.default}} variant='outlined' elevation={0} >
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sx={{minHeight:'150px'}}>
                                          <Typography variant="caption" sx={{my:2, textAlign:'justify'}}>{maintenancePlan?.leader_maintenance_plan}</Typography>
                                        </Grid>
                                      </Grid>
                                    </Card>
                                  </Grid>
                              </Grid>
                            </CardContent>
                          </Card> 
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Card sx={{borderRadius : 2, height:'auto'}} variant='outlined' elevation={0}>
                            <CardHeader
                              title={ <Box sx={{display: 'flex', justifyContent:'center',  flexDirection:'row'}}>
                                          <GroupsIcon /> 
                                          <Typography sx={{ marginTop:'4px', marginLeft:'5px', fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold',}}>Clientes e Pares</Typography>
                                      </Box>
                                    }
                              action={<EASButtonList 
                                        title="Editar Estratégias para Clientes e Pares"
                                        onClick={() => handleClickEdit('clients_and_peers')}
                                        typeIcon="edit"
                                      />}
                            />
                            <Divider/>
                            <CardContent >
                              <Grid container spacing={1}>
                                  <Grid item xs={12} sx={{minHeight:'70px'}}>
                                      <Typography variant="h5" sx={{ fontSize: '0.85rem', lineheight:'1.334'}}>Descreva quais combinações, rotinas ou mudanças foram feitas na rotina com clientes, pares ou apoios, que ajudarão na manutenção dos novos comportamentos</Typography>
                                  </Grid>  
                                  <Grid item xs={12}>
                                    <Card sx={{borderRadius : 2, p:2, mb:1, bgcolor: theme.palette.background.default}} variant='outlined' elevation={0} >
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sx={{minHeight:'150px'}}>
                                          <Typography variant="caption" sx={{my:2, textAlign:'justify'}}>{maintenancePlan?.clients_and_peers_maintenance_plan}</Typography>
                                        </Grid>
                                      </Grid>
                                    </Card>
                                  </Grid>
                              </Grid>
                            </CardContent>
                          </Card>   
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <MaintenancePlanItemEdit strategies={maintenancePlan} itemEdit={itemEdit} open={openEdit} setOpen={(e) => setOpenEdit(e)} setUpdateList={() => setUpdateList(!updateList)} />
                </CardContent>
              </Card>
            </Grid>
            </>
          )}
          </Grid>
          <EASDialogAlert 
            type="warning"
            title="Excluir Apredizado"
            statusOpen={opeLessonRemove}
            actionCancel={handleCancelDeleteLesson}
            actionConfirme={handleConfirmeDeleteLesson}
            loading={isLoading}
          >
            <Typography>Você tem certeza que deseja excluir este aprendizado?</Typography>
          </EASDialogAlert>
        </EASCard> 
      );
  };
